import { getBreadcrumbFromPermalink, sha256Hash } from '@/../shared/utils/utils'
import {
    entries,
    get,
    isEmpty,
    isEqual,
    isNil,
    last,
    mapKeys,
    merge,
    omitBy,
    replace,
    snakeCase,
    uniqWith,
    values,
    reject
} from 'lodash'
import { mapGetters, mapActions } from 'vuex'

export default {
    computed: {
        ...mapGetters('order', [
            'getShippingAddress'
        ]),
        ...mapGetters('states', [
            'getStates'
        ]),
        ...mapGetters('user', [
            'getUserData',
            'getUserAddress'
        ]),
        gtmEmail () {
            return this.$store?.getters['auth/getEmail']
        },
        gtmSignedIn () {
            return !!this.$store?.getters['auth/getEmail']
        }
    },
    async created () {
        // await Promise.all([this.getGtmUser(), this.getGtmAddress()])
    },
    watch: {
        async gtmSignedIn (newValue) {
            if (newValue) {
                // await Promise.all([this.getGtmUser(), this.getGtmAddress()])
            } else {
                this.clearUserData()
                this.clearUserAddress()
            }
        }
    },
    methods: {
        ...mapActions('user', [
            'fetchUserData',
            'fetchUserAddress',
            'clearUserData',
            'clearUserAddress'
        ]),
        gtmSearchEvent(search_term) {
            this.pushDataToDataLayer({
                'event': 'SearchNuxtEvent',
                'CustomSearchInput': search_term,
            })
        },
        gtmItems (lineItems) {
            return [lineItems].flat().map((lineItem, index) => this.gtmItem(lineItem, index))
        },
        gtmItem (item, index) {
            const categories = this.getItemCategories(item)
            const itemList = this.getItemListFromRoute(item.list_page_metadata)
            const name = item.name || item.product_name || item.variant?.name

            return {
                item_id: item.variant?.id,
                item_name: name,
                item_category: categories[0],
                item_category2: categories[1],
                item_category3: categories[2],
                price: item.price,
                quantity: item.quantity,
                item_brand: item.supplier_name,
                affiliation: item.supplier_name,
                coupon: null,
                item_list_id: itemList.id,
                item_list_name:  itemList.name,
                location_id: null, // the physical store location
                creative_name: null, // name of promotional banner or ad content
                creative_slot: null, // where the creative is placed
                promotion_id: null,
                promotion_name: null,
                item_variant: item.variant.option_name,
                index
            }
        },
        getItemListFromRoute (route) {
            return {
                id: route?.path,
                name: route?.params?.name || route?.name
            }
        },
        getItemCategories ({ category_permalink }) {
            const parts = category_permalink?.split('/') || []
            return parts.map(getBreadcrumbFromPermalink)
        },
        getOrderShippingTier ({ adjustments }) {
            const whiteGlove = (adjustments || []).some(({ label }) => label === 'White Glove Shipping')
            return whiteGlove ? 'white_glove' : 'threshold'
        },
        buildGtmLineItem (lineItem) {
            return { ...lineItem, name: lineItem.product_name }
        },
        getGtmLineItems (lineItems) {
            return (lineItems || []).map(this.buildGtmLineItem)
        },
        async pushDataToDataLayer (data) {
            data = { 'UserDataNuxt': await this.gtmEnhancedConversionData(), ...data }
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({ ItemsNuxt: null })
            window.dataLayer.push(data)
        },
        gtm40kStep (step) {
            console.log(this.gtm40kStepEventName(step))
            if (!Number.isInteger(step) || step < 0 || step > 10) return

            this.pushDataToDataLayer({
                'event': '40kStepNuxtEvent',
                '40kStepNuxt': this.gtm40kStepEventName(step)
            })
        },
        gtm40kStepEventName (step) {
            const stepsEventNames = [
                '40k step 0 - landing page',
                '40k step 1 - contact form',
                '40k step 2 - room selection',
                '40k step 3 - furniture selection',
                '40k step 4 - style selection',
                '40k step 5 - brand selection',
                '40k step 6 - home details and outdoor',
                '40k step 7 - text',
                '40k step confirmation email not used but needed to have the right order',
                '40k step 8 - success',
                '40k step 9 - social share'
            ]

            return stepsEventNames[step]
        },
        gtmPurchaseEvent({ number, total, tax_adjustments_total, shipments_total, promotion, line_items }) {
            this.pushDataToDataLayer({
                'event': 'PurchaseNuxtEvent',
                'TransactionNuxt': number,
                'ValueNuxt': total,
                'TaxNuxt': tax_adjustments_total,
                'ShippingNuxt': shipments_total,
                'CouponNuxt': promotion?.name,
                'ItemsNuxt': this.gtmItems(line_items)
            })
        },
        gtmSelectItem(listPageMetadata, lineItems) {
            const itemList = this.getItemListFromRoute(listPageMetadata)

            this.pushDataToDataLayer({
                'event': 'SelectItemNuxtEvent',
                'ItemListIdNuxt': itemList.id,
                'ItemListNameNuxt': itemList.name,
                'ItemsNuxt': this.gtmItems(lineItems)
            })
        },
        gtmViewItem(total, lineItems) {
            this.pushDataToDataLayer({
                'event': 'ViewItemNuxtEvent',
                'ValueNuxt': total,
                'ItemsNuxt': this.gtmItems(lineItems)
            })
        },
        gtmAddToCart(total, lineItems) {
            this.pushDataToDataLayer({
                'event': 'AddToCartNuxtEvent',
                'ValueNuxt': total,
                'ItemsNuxt': this.gtmItems(lineItems)
            })
        },
        gtmRemoveFromCart(total, lineItems) {
            this.pushDataToDataLayer({
                'event': 'RemoveFromCartNuxtEvent',
                'ValueNuxt': total,
                'ItemsNuxt': this.gtmItems(lineItems)
            })
        },
        gtmSelectPromotion({ promotion, line_items }) {
            const lineItems = this.getGtmLineItems(line_items)

            this.pushDataToDataLayer({
                'event': 'SelectPromotionNuxtEvent',
                'PromotionIdNuxt': promotion.id,
                'PromotionNameNuxt': promotion.name,
                'PromotionCreativeName': null,
                'PromotionCreativeSlot': null,
                'ItemsNuxt': this.gtmItems(lineItems)
            })
        },
        gtmBeginCheckout({ total, promotion, line_items }) {
            const lineItems = this.getGtmLineItems(line_items)

            this.pushDataToDataLayer({
                'event': 'BeginCheckoutNuxtEvent',
                'ValueNuxt': total,
                'CouponNuxt': promotion?.name,
                'ItemsNuxt': this.gtmItems(lineItems)
            })
        },
        buildGtmLineItemFromAlgolia (product) {
            let categoryPermalink = get(product, 'category', '')
                categoryPermalink = values(categoryPermalink)
                categoryPermalink = last(categoryPermalink)
                categoryPermalink = replace(categoryPermalink, /\s+>\s+/g, '/')

            let optionName = get(product, 'options', [])
                optionName = merge(...optionName)
                optionName = entries(optionName)
                optionName = optionName
                    .filter(entry => entry[0] !== 'sku')
                    .map(entry => entry[1])
                    .join(' ')

            return {
                name: product.proper_name,
                price: product.price,
                quantity: 1,
                supplier_name: get(product, 'supplier.name', ''),
                category_permalink: categoryPermalink,
                list_page_metadata: null,
                variant: {
                    id: product.objectID,
                    option_name: optionName
                }
            }
        },
        gtmViewItemList(listPageMetadata, lineItems) {
            const itemList = this.getItemListFromRoute(listPageMetadata)

            this.pushDataToDataLayer({
                'event': 'ViewItemListNuxtEvent',
                'ItemListIdNuxt': itemList.id,
                'ItemListNameNuxt': itemList.name,
                'ItemsNuxt': this.gtmItems(lineItems)
            })
        },
        gtmViewCart({ total, line_items }) {
            const lineItems = this.getGtmLineItems(line_items)

            this.pushDataToDataLayer({
                'event': 'ViewCartNuxtEvent',
                'ValueNuxt': total,
                'ItemsNuxt': this.gtmItems(lineItems)
            })
        },
        gtmAddShippingInfo(order) {
            const { total, promotion, line_items } = order
            const lineItems = this.getGtmLineItems(line_items)

            this.pushDataToDataLayer({
                'event': 'AddShippingInfoNuxtEvent',
                'ValueNuxt': total,
                'CouponNuxt': promotion?.name,
                'ShippingTierNuxt': this.getOrderShippingTier(order),
                'ItemsNuxt': this.gtmItems(lineItems)
            })
        },
        gtmAddPaymentInfo(order, paymentType) {
            const { total, promotion, line_items } = order
            const lineItems = this.getGtmLineItems(line_items)

            this.pushDataToDataLayer({
                'event': 'AddPaymentInfoNuxtEvent',
                'ValueNuxt': total,
                'CouponNuxt': promotion?.name,
                'PaymentTypeNuxt': paymentType,
                'ItemsNuxt': this.gtmItems(lineItems)
            })
        },
        gtmTradeDesignerApplied() {
            this.pushDataToDataLayer({
                'event': 'TradeDesignerApplied'
            })
        },
        async gtmEnhancedConversionData () {
            const sha256EmailAddress =
                await this.gtmNormalizedArrayValue(
                    [this.getShippingAddress?.email, this.gtmEmail],
                    async email => await sha256Hash(this.gtmNormalizedValue(email))
                )
            const sha256PhoneNumber =
                await this.gtmNormalizedArrayValue(
                    [this.getShippingAddress?.phone, this.getUserData?.phone, this.getUserAddress?.phone],
                    async phone => await sha256Hash(this.gtmNormalizedPhoneValue(phone))
                )
            const address =
                await this.gtmNormalizedArrayValue(
                    [this.getUserAddress, this.getShippingAddress],
                    async address => await this.gtmNormalizedAddressValue(address)
                )
            let value = omitBy({ sha256EmailAddress, sha256PhoneNumber, address }, isEmpty)

            return this.gtmMapUserDataKeys(value)
        },
        async gtmNormalizedArrayValue (value, fn) {
            value = reject(value, isEmpty).map(fn)
            value = await Promise.all(value)
            value = reject(value, isEmpty)
            value = uniqWith(value, isEqual)

            return value
        },
        async gtmNormalizedAddressValue (value) {
            if (isEmpty(value)) return

            const requiredFields = ['first_name', 'last_name', 'zip_code']
            if (requiredFields.some(field => isNil(value[field]))) return

            value = {
                sha256FirstName: await sha256Hash(this.gtmNormalizedValue(value.first_name)),
                sha256LastName: await sha256Hash(this.gtmNormalizedValue(value.last_name)),
                street: this.gtmNormalizedValue(value.address1),
                city: this.gtmNormalizedValue(value.city),
                region: this.gtmNormalizedValue(value.state?.name),
                postalCode: this.gtmNormalizedValue(value.zip_code),
                country: 'US'
            }

            value = this.gtmMapUserDataKeys(value)
            value = omitBy(value, isNil)

            return value
        },
        gtmMapUserDataKeys (value) {
            // There should be no underscores between letters and digits in snake case
            return mapKeys(value, (_, key) => snakeCase(key).replace(/(\w)_(\d)/g, '$1$2'))
        },
        gtmNormalizedValue (value) {
            return value?.toString()?.trim()?.toLowerCase()
        },
        gtmNormalizedPhoneValue (value) {
            value = this.gtmNormalizedValue(value)?.replace(/[^\d]/g, '')
            if (value?.length !== 10) return

            return `+1${value}`
        },
        async getGtmUser() {
            if (!this.gtmSignedIn) return
            if (!!this.getUserData) return

            try {
                await this.fetchUserData()
            } catch (error) {
                console.error(error)
            }
        },
        async getGtmAddress () {
            if (!this.gtmSignedIn) return
            if (!!this.getUserAddress) return

            try {
                await this.fetchUserAddress()
            } catch (error) {
                console.error(error)
            }
        }
    }
}
